<script setup>
import Image from "~/components/atoms/Image.vue";

const props = defineProps({
  lazy: {Boolean, default: false},
  src: {String, required: true},
  mobileSrc: {String, required: false},
  alt: {String, required: false},
  title: {String, required: false},
  subTitle: {String, required: false},
  url: {String, required: false},
  textAlign: {String, required: false, default: 'center'},
})
</script>

<template>
  <nuxt-link :to="props.url" class="home-grid-item" :class="{'no-shadow':!props.title}">
    <Image :loading="lazy ? 'lazy' : ''" :src="props.src" :mobile-src="props.mobileSrc ?? props.src"
           :alt="props.title"/>
    <div class="title g-4" :class="{'left-align':textAlign==='left'}" :style="{textAlign:props.textAlign}">
      <p>{{ props.title }}</p>
      <p class="sub-title" v-if="props.subTitle">{{ props.subTitle }}</p>
    </div>
    <slot/>
  </nuxt-link>
</template>

<style scoped lang="scss">
a {
  display: block;
  flex: 1;
  aspect-ratio: 1/0.74;

  &:not(.no-shadow):after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    height: 80%;
    background-blend-mode: overlay;
    background: rgba(0, 16, 41, 0.2);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50.12%, rgba(0, 0, 0, 0.25) 75.06%, rgba(0, 0, 0, 0.25) 100%);
  }

  .title {
    bottom: 12%;
    position: absolute;
    z-index: 99;
    width: 100%;
    padding: 0 24px;

    p {
      letter-spacing: .44px;
      color: $white;
      font-size: 22px;
      text-transform: uppercase;
      font-weight: 500;

      @include smd {
        font-size: 12px;
        min-width: 76px;
      }

      @include medium {
        font-size: 20px;
      }

      &.sub-title {
        text-transform: none;
        font-size: 14px;

        @include smd {
          display: none;
        }
      }
    }

    &.left-align {
      p {
        width: 237px;
        line-height: 30.05px;
      }
    }
  }
}
</style>
